import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import logo from '../images/EDING_Telecom_Logo.svg'

class Header extends React.Component{
  constructor (props) {
    super(props)
    this.state = {
        showMenuClass: '',
    }
    this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
}

toggleMenuMobile () {
    if (this.state.showMenuClass === '') {
        this.setState({
            showMenuClass: 'show'
        })
    }
    else{
        this.setState({
            showMenuClass: ''
        })
    }     
  }
  render(){
    return(
    <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "menu-1" } }
        ) {
          edges {
            node {
              slug
              name
              items {
                title
                url
                object_slug
                wordpress_children {
                  title
                  url
                  object_slug
                  wordpress_parent
                  wordpress_children {
                    title
                    url
                    object_slug
                  }
                }
              }
            }
          }
        }

        allWordpressPage(filter: {slug: {eq: "postavke-stranice"}}) {
            edges {
            node {
                id
                acf {
                glavni_logo
                {
                  localFile{
                            childImageSharp{
                                resolutions(width:553, height:111){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                }
                }
            }
            }
        }
      }
    `}
    render={data => (
        <div className="header">
        <section id="_header" className="section">
            <div className="container is-fluid">
                <nav id="_menu" className="navbar">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                                <img src={logo} alt="EDING Telecom - ways to connect" width="265" height="53" />
                        </Link>
                        <div className={"navbar-burger burger " + this.state.showMenuClass} data-target="_navBarMain"  onClick={this.toggleMenuMobile.bind(this)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        </div>
                    </div>

                    <div id="_navBarMain" className={"navbar-menu " + this.state.showMenuClass}>
                    <ul className="navbar-start">
                    <li><Link to={"/"} className="navbar-item _link"> <span>POČETNA</span>  </Link></li>
                    {data &&
              data.allWordpressWpApiMenusMenusItems &&
              data.allWordpressWpApiMenusMenusItems.edges &&
              data.allWordpressWpApiMenusMenusItems.edges[0] &&
              data.allWordpressWpApiMenusMenusItems.edges[0].node &&
              data.allWordpressWpApiMenusMenusItems.edges[0].node.items &&
              data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
                prop => {
                  return (
                    <li key={prop.object_slug}>
                    {prop.url === "#" && (
                    <Link to={"/#"} className="navbar-item _link">
                      <span>{prop.title}</span>
                    </Link>
                    )}
                    {prop.url !== "#" && (
                    <Link to={"/"+prop.object_slug} className="navbar-item _link">
                      <span>{prop.title}</span>
                    </Link>
                    )}
                    {prop.wordpress_children !== null && (
                    <section className="section submenus">
                    <ul className="container f-sub columns">
                        {prop.wordpress_children.map((item_child) =>
                        <li key={item_child.object_slug} className={item_child.classes}>
                          {item_child.wordpress_parent === 109 && (
                            <Link to={"/projekti/"+item_child.object_slug}>
                              {item_child.title}<span className="icon-next"></span>
                          </Link>
                          )}
                           {item_child.wordpress_parent !== 109 && (
                              <>
                              {item_child.url === "#" && (
                                <Link to={"/#"}>
                                {item_child.title}<span className="icon-next"></span>
                                </Link>
                                )}
                                {item_child.url !== "#" && (
                                <Link to={"/"+item_child.object_slug}>
                                    {item_child.title}<span className="icon-next"></span>
                                </Link>
                                )}
                              </>
                           )}
                          
                           
                            {item_child.wordpress_children !== null && (<ul className="s-sub">
                            {item_child.wordpress_children.map((item_subchild) =>
                            <li key={item_subchild.object_slug} className={item_subchild.classes}>
                                <Link to={"/" + item_subchild.object_slug}>
                                    {item_subchild.title}
                                </Link>
                            </li>
                            )}
                        </ul>)}
                        </li>
                        )}
                    </ul>
                    </section>
                    )}
                </li>
                    )
                }
                
              )}
               <li><Link to={"/kontakt"} className="navbar-item _link"> <span>KONTAKT</span>  </Link></li>
            </ul> 

            <div className="navbar-end">
            <div className="navbar-item">
                <div className="field is-grouped">
                    <Link to="/" className="navbar-item _link">EN</Link>
                    <Link to="/" className="navbar-item _link">DE</Link>
                    <Link to="/" className="navbar-item _link">B/H/S</Link>
                <p className="control">
                    <Link to="/kontakt" className="button _btnHeader">Kontakt</Link>
                </p>
                </div>
            </div>
            </div>
                        </div>
                    </nav>
                </div>
            </section>
        </div>
    )}
  />
)
              }
            }

export default Header
