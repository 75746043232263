import React from 'react'
import Helmet from 'react-helmet'
import Footer from './footer'
import Header from "./header"
import Navigation from './navigation'
import "../scss/index.scss"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'

library.add(faFacebookF, faTwitter, faEnvelope)

const TemplateWrapper = ({props, children }) => (
  <div>
    
    <Header />
    <div>{children}</div>
    <Footer />
  </div>
)

export default TemplateWrapper
