import logo from '../images/EDING_Telecom_Logo_white.svg'
import img_logo_small from '../images/eding_logo_small.svg'
import img_map from '../images/maps_img.png'
import { StickyContainer, Sticky } from 'react-sticky';
import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"

const Footer = () => (
    <StaticQuery
    query={graphql`
      query {
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              slug
              name
              items {
                title
                url
                object_slug
              }
            }
          }
        }
        allWordpressPage(filter: {slug: {eq: "postavke-stranice"}}) {
            edges {
            node {
                id
                acf {
                lokacija_1_naziv
                lokacija_2_naziv
                lokacija_3_naziv
                lokacija_4_naziv
                lokacija_1_adresa
                lokacija_2_adresa
                lokacija_3_adresa
                lokacija_4_adresa
                lokacija_1_telefon
                lokacija_2_telefon
                lokacija_3_telefon
                lokacija_4_telefon
                facebook,
                twitter,
                instagram,
                linkedin,
                sporedni_logo{
                    localFile{
                            childImageSharp{
                                resolutions(width:83, height:65){
                                    src
                                    width
                                    height
                                }
                            }
                        }
                }
                }
            }
            }
        }
      }
    `}
    render={data => (
        <div className="footer">
    {/* <section id="_locations" className="section">
        <StickyContainer>
            <div className="container is-fluid">
                <div className="columns is-gapless">
                    <div className="column">
                        <div className="__innerContainer">
                            <div className="_inner">
                                <span className="_subtitle">Lokacija</span>
                                <div className="_box">
                                    <div className="title">
                                        <span className="image">
                                            <img src={img_logo_small} alt="EDING Telecom"/>
                                        </span>
                                        {data.allWordpressPage.edges[0].node.acf.lokacija_1_naziv}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: data.allWordpressPage.edges[0].node.acf.lokacija_1_adresa }}/>
                                    <p className="content">
                                        t: <a href={"tel:"+data.allWordpressPage.edges[0].node.acf.lokacija_1_telefon}>{data.allWordpressPage.edges[0].node.acf.lokacija_1_telefon}</a>
                                    </p>
                                </div>
                                <div className="_box">
                                    <div className="title">
                                        <span className="image">
                                            <img src={img_logo_small} alt="EDING Telecom"/>
                                        </span>
                                        {data.allWordpressPage.edges[0].node.acf.lokacija_2_naziv}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: data.allWordpressPage.edges[0].node.acf.lokacija_2_adresa }}/>
                                    <p className="content">
                                        t: <a href={"tel:"+data.allWordpressPage.edges[0].node.acf.lokacija_2_telefon}>{data.allWordpressPage.edges[0].node.acf.lokacija_2_telefon}</a>
                                    </p>
                                </div>
                                <div className="_box">
                                    <div className="title">
                                        <span className="image">
                                            <img src={img_logo_small} alt="EDING Telecom"/>
                                        </span>
                                        {data.allWordpressPage.edges[0].node.acf.lokacija_3_naziv}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: data.allWordpressPage.edges[0].node.acf.lokacija_3_adresa }}/>
                                    <p className="content">
                                        t: <a href={"tel:"+data.allWordpressPage.edges[0].node.acf.lokacija_3_telefon}>{data.allWordpressPage.edges[0].node.acf.lokacija_3_telefon}</a>
                                    </p>
                                </div>
                                <div className="_box">
                                    <div className="title">
                                        <span className="image">
                                            <img src={img_logo_small} alt="EDING Telecom"/>
                                        </span>
                                        {data.allWordpressPage.edges[0].node.acf.lokacija_4_naziv}
                                    </div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: data.allWordpressPage.edges[0].node.acf.lokacija_4_adresa }}/>
                                     <p class="content"> t: <a href={"tel:"+data.allWordpressPage.edges[0].node.acf.lokacija_4_telefon}>{data.allWordpressPage.edges[0].node.acf.lokacija_4_telefon}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-6 is-hidden-touch">
                        
                            <Sticky>
                            {({
                                style,
                                isSticky,
                                calculatedHeight
                            }) => (
                                <div className="_mapImage" style={style}>
                                    <img src={img_map} alt="EDING Telecom - Our locations"/>
                                </div>
                            )}
                            </Sticky>
                            
                    </div>
                </div>
            </div>
        </StickyContainer>
    </section>*/}
   
    <section id="_footer_menu" className="section">
        <div className="container">
            <div className="columns">
            {data.allWordpressWpApiMenusMenusItems.edges.map(menu => (
                <>
                {console.log(menu)}
                 {menu.node.slug !== "menu-1" && (
                <div className="column">
                    <div className="inner">
                        <h4 className="menu-title">{menu.node.name}</h4>
                        <ul>
                            {menu.node.items.map(prop => (
                                <li key={prop.object_slug}>
                                    <Link to={prop.object_slug}>{prop.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                 )}
                </>
            )
               
            )}
            </div>
        </div>
    </section>

    <section id="_footer" className="section">
        <div className="container is-fluid">
            <div className="logo">
                <img src={logo} alt="EDING Telecom"/>
            </div>
            <div class="text-block">
                <h4>ISO 9001 <span>|</span> ISO 14001 <span>|</span> ISO 27001 <span>|</span> ISO 3834</h4>
                <p>© EDING Telecom. Sva prava zadržana. Created by <a href="https://wellpromotion.ba" target="_blank">WELL BUSINESS PROMOTION</a>.</p>
            </div>
            <div className="text-block mb-0">
                <p>Pronađite nas i na društvenim mrežama</p>
                <div className="social-box">
                    <a className="facebook" href={data.allWordpressPage.edges[0].node.acf.facebook} target="_blank"><i></i></a> 
                    <a className="instagram" href={data.allWordpressPage.edges[0].node.acf.instagram} target="_blank"><i></i></a> 
                    <a className="twitter" href={data.allWordpressPage.edges[0].node.acf.twitter} target="_blank"><i></i></a> 
                    <a className="linkedin" href={data.allWordpressPage.edges[0].node.acf.linkedin} target="_blank"><i></i></a> 
                </div>
            </div>
        </div>
    </section>
   {/* <section id="_footer" className="section">
        <div className="container is-fluid">
            <div className="columns">
                <div className="column">
                    <div className="_inner">
                        <div className="_imageLogo">
                            <img src={data.allWordpressPage.edges[0].node.acf.sporedni_logo.localFile.childImageSharp.resolutions.src} alt="EDING Telecom"/>
                        </div>
                        <div className="_copyright">
                            <span>© EDING Telecom. Sva prava zadržana.</span>
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="_createdby">
                        <div className="_innerby">
                            <span>Created by <a href="/">WELL BUSINESS PROMOTION</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                            </section> */}
</div>
    )}
  />
)

export default Footer
